<template>
  <dashboard-header ref="header" />
  <div class="max-w-dstp xl2:mx-auto">
    <slot />
  </div>
</template>

<script>
import DashboardHeader from "@/components/DashboardHeader.vue";
export default {
  components: { DashboardHeader },
  methods: {
    showNewMatchModal() {
      this.$refs.header.showModal();
    },
  },
};
</script>
