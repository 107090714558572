<template>
  <input
    class="p-2 bg-white border-0 rounded-lg focus:ring-0 shadow-ts placeholder:text-gray-400/90"
    v-bind="$attrs"
    :type="type"
    :name="name"
    :placeholder="placeholder"
    :value="modelValue"
    :required="required"
    @input="$emit('update:modelValue', $event.target.value)"
  />
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "text",
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    required: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  emits: ["update:modelValue"],
};
</script>
