import axios from "axios";
import router from "@/router";

axios.defaults.headers["Content-Type"] = "application/json";
// axios.defaults.headers["Access-Control-Allow-Origin"] = "*";
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

async function returnToken() {
  const storedValue = localStorage.getItem("token");
  const [token, storedTimestamp] = storedValue
    ? storedValue.split("_")
    : [null, null];
  const currentTimestamp = new Date().getTime();
  if (storedTimestamp && currentTimestamp - storedTimestamp < 300000) {
    return token;
  } else {
    // Token is expired or not found, handle accordingly
    if (token) {
      await sleep(2000);
      // Token is found but expired, show an error message
      return token;
    } else {
      router.push("/login"); // Redirect to login page
    }
  }
}

const baseURL = APP_CONFIG.apiUrl + "/api/v1";
const mainAxios = axios.create({
  baseURL,
});

const customAxios = axios.create({
  baseURL,
  headers: {
    Authorization: "TOKEN " + localStorage.getItem("token"),
  },
});

const WHITELISTED_ROUTES = ["/auth/password-reset/request"];

customAxios.interceptors.request.use(
  async (config) => {
    // skip token check/redirect for these routes:
    if (WHITELISTED_ROUTES.includes(config.url)) {
      return config;
    }

    const token = await returnToken(); // Await the token from your async function
    config.headers.Authorization = "TOKEN " + token; // Update the Authorization header
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

customAxios.interceptors.response.use(
  (response) => {
    // If response is successful, return it
    return response;
  },
  (error) => {
    // If response has status code 401, redirect to login page
    if (error.response && error.response.status === 401) {
      router.push("/login"); // Redirect to login page
    }
    return Promise.reject(error);
  }
);

export { mainAxios, customAxios };
