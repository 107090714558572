<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal">
      <div class="fixed inset-0 z-30 overflow-y-auto bg-gray-300/50">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="relative inline-block w-full max-w-2xl px-16 py-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
            >
              <DialogTitle
                as="h3"
                class="mt-8 mb-12 text-4xl font-semibold leading-6 text-center capitalize text-yelloworange"
              >
                {{ $t("new_match") }}
              </DialogTitle>
              <form @submit.prevent="checkForm" class="space-y-8">
                <div class="mt-8 mb-12 row gap-y-4 md:gap-y-0">
                  <div class="relative px-0 col-md-5">
                    <div class="absolute left-0 flex items-center top-2">
                      <chevron-down-icon
                        class="w-5 h-5 mr-1 stroke-4"
                        @click.prevent="openDroplist(LEFT)"
                      />
                      <player-small-icon
                        class="mx-1 text-blue-500 w-9 h-9"
                        :class="
                          match.athleteLeftObj.guid === user.guid
                            ? 'text-blue-500'
                            : 'text-indigo-700'
                        "
                      />
                    </div>
                    <input-field
                      :name="'athleteLeft'"
                      :placeholder="$t('new_match_page.fill_athlete_name')"
                      :type="'text'"
                      v-model="match.athleteLeftObj.name"
                      ref="leftInputField"
                      :class="[
                        errors.athleteLeftGuid
                          ? 'border-red-500'
                          : 'border-transparent focus:border-transparent',
                        'w-full pr-2 pl-[72px] border-t-0 border-b-2 font-bold text-[26px] border-x-0 ring-0 focus:ring-0',
                      ]"
                      :disabled="match.athleteLeftObj.disable"
                      autocomplete="off"
                      @keydown="controlList($event, LEFT)"
                      @blur="closeDroplist(LEFT)"
                    ></input-field>
                    <div
                      class="absolute z-10 w-full overflow-y-auto bg-white rounded shadow-lg left max-h-52 top-12"
                      v-show="match.athleteLeftObj.droplist"
                      ref="LeftDroplist"
                    >
                      <div
                        v-for="(person, index) in filterOpponents"
                        :key="person"
                      >
                        <button
                          :class="[
                            selectIndex == index ? 'bg-cadmium text-white' : '',
                            'w-full px-4 py-2 text-left hover:bg-cadmium hover:text-white',
                          ]"
                          type="button"
                          @click="selectAthlete(index, LEFT)"
                        >
                          {{ person.name }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="relative order-2 px-0 col-md-5">
                    <div class="absolute right-0 flex items-center top-2">
                      <player-small-icon
                        class="mx-1 text-blue-500 w-9 h-9"
                        :class="
                          match.athleteRightObj.guid === user.guid
                            ? 'text-blue-500'
                            : 'text-indigo-700'
                        "
                      />
                      <chevron-down-icon
                        class="w-5 h-5 mr-1 stroke-4"
                        @click.prevent="openDroplist(RIGHT)"
                      />
                    </div>
                    <input-field
                      :name="'athleteRight'"
                      :placeholder="$t('new_match_page.fill_athlete_name')"
                      :type="'text'"
                      v-model="match.athleteRightObj.name"
                      ref="rightInputField"
                      :class="[
                        errors.athleteRightGuid
                          ? 'border-red-500'
                          : 'border-transparent focus:border-transparent',
                        'w-full pr-[72px] pl-2 border-t-0 border-b-2 border-x-0 font-bold text-[26px] ring-0 focus:ring-0',
                      ]"
                      @keydown="controlList($event, RIGHT)"
                      @blur="closeDroplist(RIGHT)"
                      autocomplete="off"
                      :disabled="match.athleteRightObj.disable"
                    ></input-field>
                    <div
                      class="absolute z-10 w-full overflow-y-auto bg-white rounded shadow-lg right max-h-52 top-12"
                      v-show="match.athleteRightObj.droplist"
                      ref="RightDroplist"
                    >
                      <div
                        v-for="(person, index) in filterOpponents"
                        :key="person"
                      >
                        <button
                          :class="[
                            selectIndex == index ? 'bg-cadmium text-white' : '',
                            'w-full px-4 py-2 text-left hover:bg-cadmium hover:text-white',
                          ]"
                          type="button"
                          @click="selectAthlete(index, RIGHT)"
                        >
                          {{ person.name }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="order-1 text-center col-md-2">
                    <button
                      type="button"
                      class="inline-flex items-center hover:text-cadmium bg-white p-4 rounded-full shadow-[0px_7px_18px_rgba(0,0,0,0.12)]"
                      @click="swapAthlete()"
                    >
                      <switch-horizontal-icon class="w-5 h-5" />
                      <span class="sr-only">{{ $t("swap") }}</span>
                    </button>
                  </div>
                </div>
                <p class="text-red-500" v-if="errors.athleteLeftGuid">
                  {{ errors.athleteLeftGuid }}
                </p>
                <p class="text-red-500" v-if="errors.athleteRightGuid">
                  {{ errors.athleteRightGuid }}
                </p>
                <div
                  class="grid grid-cols-1 gap-8 px-6 my-8 capitalize sm:grid-cols-2"
                >
                  <div>
                    <p class="mb-2 text-lg font-bold text-cadmium">
                      {{ $t("weapon_type") }}
                    </p>
                    <div class="flex items-center justify-start space-x-5">
                      <div
                        v-for="(wt, ix) in getWeaponTypes"
                        :key="wt + ix"
                        class="flex items-center"
                      >
                        <input
                          type="radio"
                          :id="wt + ix"
                          name="weapontype"
                          v-model="match.weaponType"
                          :value="wt"
                          class="mr-2 border-2 border-gray-300 checked:bg-gradient-to-b checked:text-yelloworange checked:from-yelloworange checked:to-cadmium focus:ring-yelloworange fill-yelloworange"
                        />
                        <label :for="wt + ix" class="text-lg capitalize">{{
                          $t(wt ? wt : "unknown")
                        }}</label>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p class="mb-2 text-lg font-bold text-cadmium">
                      {{ $t("match_type") }}
                    </p>
                    <div class="flex items-center justify-start space-x-5">
                      <div
                        v-for="(mt, inx) in matchTypes"
                        :key="mt + inx"
                        class="flex items-center"
                      >
                        <input
                          type="radio"
                          :id="mt.id"
                          name="matchTypeId"
                          v-model="match.matchTypeId"
                          :value="mt.id"
                          class="mr-2 border-2 border-gray-300 checked:bg-gradient-to-b checked:text-yelloworange checked:from-yelloworange checked:to-cadmium focus:ring-yelloworange fill-yelloworange"
                        />
                        <label :for="mt.id" class="text-lg capitalize">{{
                          $t(mt.codeName)
                        }}</label>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p class="mb-2 text-lg font-bold text-cadmium">
                      {{ $t("date_and_time") }}
                    </p>
                    <input-field
                      :name="'datetime'"
                      :type="'datetime-local'"
                      v-model="match.matchDateTime"
                    />
                  </div>
                  <div v-if="matchIsTournament">
                    <p class="mb-2 text-lg font-bold text-cadmium">
                      {{ $t("phase_type") }}
                    </p>
                    <div class="flex items-center justify-start space-x-5">
                      <div
                        v-for="(pt, ind) in getPhaseTypes"
                        :key="pt + ind"
                        class="flex items-center"
                      >
                        <input
                          type="radio"
                          :id="pt + ind"
                          name="phasetype"
                          v-model="match.phaseType"
                          :value="pt"
                          class="mr-2 border-2 border-gray-300 checked:bg-gradient-to-b checked:text-yelloworange checked:from-yelloworange checked:to-cadmium focus:ring-yelloworange fill-yelloworange"
                        />
                        <label :for="pt + ind" class="text-lg capitalize">{{
                          $t(pt)
                        }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <primary-button
                  class="float-right px-16 shadow-tb"
                  :type="'submit'"
                  :loading="loading"
                  ><span class="capitalize">{{ $t("start") }}</span>
                </primary-button>
              </form>
              <button
                type="button"
                @click="closeModal()"
                class="absolute text-gray-400/90 top-6 right-6"
              >
                <svg
                  class="w-7 h-7"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
                <span class="sr-only">{{ $t("close") }}</span>
              </button>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import InputField from "@/components/forms/InputField.vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { ref } from "vue";
import PrimaryButton from "@/components/forms/PrimaryButton.vue";
import moment from "moment";
import debounceLodash from "lodash/debounce";
import { v4 as uuidv4 } from "uuid";
import athleteServices from "@/services/athleteServices";
import { FOIL, getWeaponTypes } from "@/enums/weaponTypes";
import { getPhaseTypes } from "@/enums/phaseTypes";
import { LEFT, RIGHT } from "@/enums/positionTypes";
import Logo from "@/assets/images/default/logo.png";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";
import SwitchHorizontalIcon from "@/components/icons/SwitchHorizontalIcon.vue";
import ChevronDownIcon from "@/components/icons/ChevronDownIcon.vue";
import PlayerSmallIcon from "@/components/icons/PlayerSmallIcon.vue";
// import ChevronDownIcon from "@/components/icons/ChevronDownIcon.vue";
import { TOURNAMENT } from "@/enums/matchTypes";
import { PROFESSIONAL } from "@/enums/licenceTypes";

export default {
  setup() {
    const isOpen = ref(false);

    function closeModal() {
      isOpen.value = false;
    }

    function openModal() {
      isOpen.value = true;
      this.match.weaponType = this.user.preferredWeapon || FOIL;
      this.match.athleteLeftGuid = this.user.guid;
      this.match.athleteLeftObj = {
        name: this.user.name,
        guid: this.user.guid,
        disable: this.user.licenceType !== PROFESSIONAL,
        droplist: false,
      };
      this.match.athleteRightObj = {
        name: null,
        guid: null,
        disable: false,
        droplist: false,
      };
    }

    return {
      isOpen,
      closeModal,
      openModal,
    };
  },
  name: "NewMatch",
  components: {
    PlayerSmallIcon,
    ChevronDownIcon,
    InputField,
    PrimaryButton,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
    SwitchHorizontalIcon,
  },
  data() {
    return {
      LEFT,
      RIGHT,
      errors: {},
      loading: false,
      weaponType: null,
      getWeaponTypes,
      getPhaseTypes,
      selectIndex: -1,
      Logo,
      match: {
        matchDateTime: moment(new Date()).format("YYYY-MM-DD HH:mm"),
        athleteRightGuid: null,
        matchTypeId: null,
        phaseType: "pool",
        athleteLeftObj: {
          name: null,
          guid: null,
          disable: true,
          droplist: false,
        },
        athleteRightObj: {
          name: null,
          guid: null,
          disable: false,
          droplist: false,
        },
      },
    };
  },
  computed: {
    ...mapState(["match1", "user", "opponents", "matchTypes"]),
    ...mapGetters(["defaultMatchTypeId", "matchTypeNameById"]),
    ...mapMutations["setNewMatch"],
    ...mapActions["getMatch"],
    matchIsTournament() {
      return this.matchTypeNameById(this.match?.matchTypeId) == TOURNAMENT;
    },
    filterOpponents() {
      return [...this.opponents].filter((person) => {
        if (
          !this.match.athleteRightObj.disable &&
          this.match.athleteRightObj.droplist &&
          person.name
        ) {
          if (this.match.athleteRightObj.name) {
            return (
              person.name
                .toLowerCase()
                .replace(/\s+/g, "")
                .indexOf(
                  this.match.athleteRightObj.name
                    .toLowerCase()
                    .replace(/\s+/g, "")
                ) > -1
            );
          } else {
            return person.name;
          }
        }
        if (
          !this.match.athleteLeftObj.disable &&
          this.match.athleteLeftObj.droplist &&
          person.name
        ) {
          if (this.match.athleteLeftObj.name) {
            return (
              person.name
                .toLowerCase()
                .replace(/\s+/g, "")
                .indexOf(
                  this.match.athleteLeftObj.name
                    .toLowerCase()
                    .replace(/\s+/g, "")
                ) > -1
            );
          } else {
            return person.name;
          }
        }
      });
      // }
    },
  },
  async mounted() {
    await this.$store.dispatch("getConfigurationPersonal");
    this.match.matchTypeId = this.defaultMatchTypeId; // load first matchTypeId dynamically

    await this.$store.dispatch("getMatch", this.match);
  },
  methods: {
    dateTime(value) {
      return moment(value).format("YYYY-MM-DDTHH:mm:ssZ");
    },

    async checkForm() {
      this.loading = true;
      if (
        this.match.athleteLeftGuid &&
        this.match.athleteRightGuid &&
        this.match.weaponType &&
        this.match.matchTypeId &&
        this.match.matchDateTime
      ) {
        this.closeModal();
        this.match.matchDateTime = this.dateTime(this.match.matchDateTime);
        if (!this.matchIsTournament) {
          this.match.phaseType = null;
        }
        this.match.guid = uuidv4();
        this.$store.commit("setNewMatch", this.match);
        this.match.matchDateTime = moment(new Date()).format(
          "YYYY-MM-DD HH:mm"
        );
        this.$router.push({ name: "newmatch" });
      }
      this.errors = [];
      if (this.match.athleteLeftGuid === null) {
        if (
          this.match.athleteLeftObj.name != null &&
          this.match.athleteLeftObj.name.trim().length > 1
        ) {
          let guid = uuidv4();
          await athleteServices
            .addAthletes(guid, {
              name: this.match.athleteLeftObj.name.trim(),
              guid: guid,
            })
            .then((response) => {
              if (response.status == 201) {
                this.match.athleteLeftGuid = response.data.guid;
                this.match.athleteLeftObj.guid = response.data.guid;
                this.checkForm();
              } else {
                this.errors.athleteLeftGuid = this.$t(
                  "error.left_athlete_required"
                );
              }
            })
            .catch((err) => {
              console.error(err.response.message);
              this.errors.athleteLeftGuid = this.$t(
                "error.left_athlete_required"
              );
            });
        } else {
          this.errors.athleteLeftGuid = this.$t("error.left_athlete_required");
        }
      }

      if (this.match.athleteRightGuid === null) {
        if (
          this.match.athleteRightObj.name != null &&
          this.match.athleteRightObj.name.trim().length > 1
        ) {
          let guid = uuidv4();
          await athleteServices
            .addAthletes(guid, {
              name: this.match.athleteRightObj.name.trim(),
              guid: guid,
            })
            .then((response) => {
              if (response.status == 201) {
                this.match.athleteRightGuid = response.data.guid;
                this.match.athleteRightObj.guid = response.data.guid;
                this.checkForm();
              } else {
                this.errors.athleteRightGuid = this.$t(
                  "right_athlete_required"
                );
              }
            })
            .catch((err) => {
              console.error(err.response.message);
              this.errors.athleteRightGuid = this.$t(
                "error.right_athlete_required"
              );
            });
        } else {
          this.errors.athleteRightGuid = this.$t(
            "error.right_athlete_required"
          );
        }
      }

      if (!this.match.weaponType) {
        this.errors.weaponType = this.$t("error.weapon_type_required");
      }
      if (this.matchIsTournament && !this.match.phaseType) {
        this.errors.phaseType = this.$t("error.phase_type_required");
      }
      if (!this.match.matchTypeId) {
        this.errors.matchTypeId = this.$t("error.match_type_required");
      }
      if (!this.match.matchDateTime) {
        this.errors.matchDateTime = this.$t("error.date_type_required");
      }
      this.loading = false;
    },

    swapAthlete() {
      let temp = this.match.athleteLeftGuid;
      this.match.athleteLeftGuid = this.match.athleteRightGuid;
      this.match.athleteRightGuid = temp;
      let tempobj = this.match.athleteLeftObj;
      this.match.athleteLeftObj = this.match.athleteRightObj;
      this.match.athleteRightObj = tempobj;
    },

    selectAthlete(index, pos) {
      if (pos == LEFT) {
        this.match.athleteLeftGuid = this.filterOpponents[index].guid;
        this.match.athleteLeftObj.name = this.filterOpponents[index].name;
        this.match.athleteLeftObj.guid = this.filterOpponents[index].guid;
      }
      if (pos == RIGHT) {
        this.match.athleteRightGuid = this.filterOpponents[index].guid;
        this.match.athleteRightObj.name = this.filterOpponents[index].name;
        this.match.athleteRightObj.guid = this.filterOpponents[index].guid;
      }
    },

    controlList(event, pos) {
      if (pos == LEFT) {
        this.match.athleteLeftObj.droplist = true;
        if (event.keyCode == 13) {
          this.match.athleteLeftGuid =
            this.filterOpponents[this.selectIndex].guid;
          this.match.athleteLeftObj.name =
            this.filterOpponents[this.selectIndex].name;
          this.match.athleteLeftObj.guid =
            this.filterOpponents[this.selectIndex].guid;
          this.match.athleteLeftObj.droplist = false;
          event.preventDefault();
        }
      }
      if (pos == RIGHT) {
        this.match.athleteRightObj.droplist = true;
        if (event.keyCode == 13) {
          this.match.athleteRightGuid =
            this.filterOpponents[this.selectIndex].guid;
          this.match.athleteRightObj.name =
            this.filterOpponents[this.selectIndex].name;
          this.match.athleteRightObj.guid =
            this.filterOpponents[this.selectIndex].guid;
          this.match.athleteRightObj.droplist = false;
          event.preventDefault();
        }
      }
      if (event.keyCode == 40) {
        if (this.selectIndex + 1 < this.filterOpponents.length) {
          this.selectIndex++;
        } else {
          this.selectIndex = 0;
        }
        this.scrollDropList(pos);
      }
      if (event.keyCode == 38) {
        if (this.selectIndex == 0) {
          this.selectIndex = this.filterOpponents.length - 1;
        } else {
          this.selectIndex--;
        }
        this.scrollDropList(pos);
      }
    },

    scrollDropList(pos) {
      if (pos == RIGHT) {
        if (this.selectIndex > 4) {
          this.$refs.RightDroplist.scrollTop = (this.selectIndex - 3) * 42;
        } else {
          this.$refs.RightDroplist.scrollTop = 0;
        }
      }
      if (pos == LEFT) {
        if (this.selectIndex > 4) {
          this.$refs.LeftDroplist.scrollTop = (this.selectIndex - 3) * 42;
        } else {
          this.$refs.LeftDroplist.scrollTop = 0;
        }
      }
    },

    openDroplist: debounceLodash(function (pos) {
      if (pos == LEFT && !this.match.athleteLeftObj.disabled) {
        this.match.athleteLeftObj.droplist = true;
        this.match.athleteRightObj.droplist = false;
      }
      if (pos == RIGHT && !this.match.athleteRightObj.disabled) {
        this.match.athleteRightObj.droplist = true;
        this.match.athleteLeftObj.droplist = false;
      }
    }, 0),

    closeDroplist: debounceLodash(function (pos) {
      if (pos == LEFT) {
        this.match.athleteLeftObj.droplist = false;
      }
      if (pos == RIGHT) {
        this.match.athleteRightObj.droplist = false;
      }
    }, 250),
  },
};
</script>
