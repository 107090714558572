import { mainAxios, customAxios } from "../http-common";

class userDataService {
  login(data) {
    return mainAxios.post("/authentication/local/login", data);
  }

  register(data) {
    return mainAxios.post("/authentication/local/register", data);
  }

  userme() {
    return customAxios.get("/me/");
  }

  changePassword(data) {
    return customAxios.post("/me/security/change-password", data);
  }

  resetPassword(data) {
    return customAxios.post("/auth/password-reset/request", data);
  }

  userLogout() {
    return customAxios.post("/me/security/logout", {});
  }

  userDelete() {
    return customAxios.delete("/me/delete");
  }

  userAthletes(guid) {
    return customAxios.get("/app/athletes/" + guid);
  }

  updateProfile(data) {
    return customAxios.post("/me/", data);
  }
}

export default new userDataService();
