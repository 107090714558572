import { customAxios } from "@/http-common";

class athleteServices {
  allAthletes() {
    return customAxios.get("/app/athletes", {
      params: {},
    });
  }

  userAthletes(guid) {
    return customAxios.get("/app/athletes/" + guid);
  }

  addAthletes(guid, data) {
    return customAxios.put("/app/athletes/" + guid, data);
  }
}

export default new athleteServices();
