import { createRouter, createWebHistory } from "vue-router";
import MainLayout from "@/layouts/MainLayout";
import store from "@/store/index.js";
// import Tr from "@/i18n/translation";

const routes = [
  {
    path: "/",
    name: "dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "@/views/DashboardView.vue"),
    meta: { auththeticated: true, layout: MainLayout },
  },
  {
    path: "/analysis",
    name: "analysis",
    component: () =>
      import(/* webpackChunkName: "analysis" */ "@/views/MyanalysisView.vue"),
    meta: { auththeticated: true, layout: MainLayout },
  },
  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "@/views/ProfileView.vue"),
    meta: { auththeticated: true, layout: MainLayout },
  },
  {
    path: "/newmatch",
    name: "newmatch",
    component: () =>
      import(/* webpackChunkName: "newmatch" */ "@/views/NewMatch.vue"),
    meta: { auththeticated: true, layout: MainLayout },
  },
  {
    path: "/:guid/editmatch",
    name: "editmatch",
    component: () =>
      import(/* webpackChunkName: "newmatch" */ "@/views/EditMatchView.vue"),
    meta: { auththeticated: true, layout: MainLayout },
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/LoginView.vue"),
  },
  {
    path: "/registration",
    name: "registration",
    component: () =>
      import(
        /* webpackChunkName: "registration" */ "@/views/RegistrationView.vue"
      ),
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () =>
      import(
        /* webpackChunkName: "forgotPassword" */ "@/views/ForgotPasswordView.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.auththeticated) {
    let token = localStorage.getItem("token");
    if (!token) {
      return next({ name: "login" });
    } else {
      // if (to.name == "editmatch") {
      // }
      if (to.name == "newmatch" && store.state.newmatch.matchTypeId == null) {
        return next({ name: "dashboard" });
      } else next();
    }
  } else next();
});

export default router;
