import { customAxios } from "@/http-common";

class matchService {
  matches(data) {
    return customAxios.get("/app/matches", {
      params: data,
    });
  }

  impersonateMatches(guid, data) {
    return customAxios.get("/app/matches/impersonate/" + guid, {
      params: data,
    });
  }

  getAthleteDetail(guid) {
    return customAxios.get(`/app/athletes/${guid}`);
  }

  getMatch(guid) {
    return customAxios.get("/app/matches/" + guid);
  }

  addMatch(guid, data) {
    return customAxios.put(`app/matches/` + guid, data);
  }

  deleteMatch(guid) {
    return customAxios.delete(`/app/matches/${guid}`);
  }
}

export default new matchService();
